<template>
  <el-button
    @click="mulUploadImgDialogVisible = true"
    type="primary"
    size="mini"
    >{{ $t('general.select-img') }}</el-button
  >
  <el-dialog
    v-model="mulUploadImgDialogVisible"
    title="Upload Img"
    width="30%"
    align-center
  >
    <el-upload
      ref="upload"
      :file-list="fileList"
      action="#"
      list-type="picture-card"
      :accept="fileType.join(',')"
      :auto-upload="true"
      :http-request="handleHttpUpload"
      :before-upload="handleBeforeUpload"
      :on-success="
        (response, file, list) => {
          uploadSuccess(response, file, list)
        }
      "
      :on-error="uploadError"
    >
      <el-icon><Plus /></el-icon>

      <template #file="{ file }">
        <div>
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <el-icon><zoom-in /></el-icon>
            </span>
            <span
              v-if="showDownLoad && !disabled"
              class="el-upload-list__item-delete"
              @click="handleDownload(file)"
            >
              <el-icon><Download /></el-icon>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <el-icon><Delete /></el-icon>
            </span>
          </span>
        </div>
      </template>
    </el-upload>

    <el-image-viewer
      v-if="dialogVisible"
      @close="dialogVisible = false"
      :url-list="[dialogImageUrl]"
    />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="mulUploadImgDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="mulUploadImgDialogVisible = false">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { Delete, Download, Plus, ZoomIn } from '@element-plus/icons'
import i18n from '@/i18n'
import { getBase64 } from '@/utils/util'
import { ElNotification } from 'element-plus'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fileSize: {
    type: Number,
    default: 5
  },
  fileType: {
    type: Array,
    default: () => ['image/jpeg', 'image/png', 'image/gif']
  },
  showDownLoad: {
    type: Boolean,
    default: false
  },
  uploadApiAction: {
    type: String,
    default: ''
  }
})
const fileList = [
  {
    name: 'food.jpeg',
    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
  },
  {
    name: 'food.jpeg',
    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
  }
]

const { t } = i18n.global
const mulUploadImgDialogVisible = ref(false)
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const disabled = ref(false)
const upload = ref('')

// 文件上传前的hooks校验
const handleBeforeUpload = (file) => {
  const imgSize = file.size / 1024 / 1024 < props.fileSize
  const imgType = props.fileType.includes(file.type)
  if (!imgType)
    ElNotification({
      title: t('general.warm-prompt-tip'),
      message: t('general.uploaded-pics-not-match-format'),
      type: 'warning'
    })
  if (!imgSize)
    setTimeout(() => {
      ElNotification({
        title: t('general.warm-prompt-tip'),
        message: t('general.uploaded-pics-not-match-size-tip', {
          size: props.fileSize
        }),
        type: 'warning'
      })
    }, 0)
  return imgType && imgSize
}

// 图片上传
const handleHttpUpload = async (options) => {
  const formData = new FormData()
  formData.append('file', options.file)

  // 调用上传文件的接口 有接口的情况下
  // const res = await store.dispatch(props.uploadApiAction, formData)
  // 没接口转成base64

  const result = await getBase64(options.file)
  console.log(result, 'result')
  // emit('update:modelValue', result)
}

// 图片上传成功
const uploadSuccess = (response, file, list) => {
  const uploadFiles = upload.value.uploadFiles
  console.log(uploadFiles, file)
  ElNotification({
    title: t('general.warm-prompt-tip'),
    message: t('general.upload-success-tip'),
    type: 'success'
  })
}

// 图片上传失败
const uploadError = () => {
  ElNotification({
    title: t('general.warm-prompt-tip'),
    message: t('general.upload-failed-tip'),
    type: 'error'
  })
}

const handleRemove = (file, fileList) => {
  const uploadFiles = upload.value.uploadFiles
  console.log(uploadFiles, 'uploadFiles')
  for (const i in uploadFiles) {
    if (file.url === uploadFiles[i].url) {
      uploadFiles.splice(i, 1)
    }
  }
}

const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}

const handleDownload = (file) => {
  console.log(file)
}
</script>
