<template>
  <el-select
    :collapse-tags="false"
    v-model="data.selectValue"
    :multiple="!isSingle"
    :placeholder="!props.disabled ? '---' : ' '"
    :filterable="props.filterable"
    :filter-method="dataFilter"
    :popper-append-to-body="props.appendToBody"
    @remove-tag="removeTag"
    @clear="clearAll"
    :clearable="props.isCanDelete"
    style="width: 100%; color: #606266"
    :disabled="props.disabled"
  >
    <el-option
      :value="data.selectTree"
      v-loading="data.treeLoading"
      element-loading-background="rgba(255, 255, 255, 0.5)"
      element-loading-text="loading"
      class="option-style"
      disabled
    >
      <el-scrollbar height="260px">
        <el-tree
          :show-checkbox="!isSingle"
          :arrordion="isSingle"
          highlight-current
          :data="data.options"
          :props="props.defaultProps"
          ref="treeNode"
          :node-key="props.defaultProps.value"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          check-on-click-node
          :default-checked-keys="defaultValue"
          :default-expanded-keys="defaultValue"
          :check-strictly="props.checkStrictly"
          @node-click="handleNodeClick"
          @check-change="handleNodeChange"
        >
        </el-tree>
      </el-scrollbar>
    </el-option>
  </el-select>
</template>

<script setup>
import { ref, reactive, nextTick, watch, defineEmits, defineProps } from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'

const store = useStore()
const treeNode = ref(null)
const emits = defineEmits([
  'update:modelValue',
  'changeSelectDataList',
  'changeTreeData',
  'clear'
])
const props = defineProps({
  // 绑定值
  modelValue: {
    type: [String, Array],
    default: () => []
  },

  // 可用选项的数组
  options: {
    type: Array,
    default: () => []
  },
  // 配置选项
  defaultProps: {
    type: Object,
    default: () => ({
      // 属性值为后端返回的对应的字段名
      children: 'children',
      label: 'name',
      value: 'id'
    })
  },
  // 是否将组件添加到body上面(组件在弹窗或者表格里面时可设为true)
  appendToBody: {
    type: Boolean,
    default: false
  },
  // 是否可搜索
  filterable: {
    type: Boolean,
    default: true
  },
  // 是否禁用下拉框
  disabled: {
    type: Boolean,
    default: false
  },
  // 父子不互相关联
  checkStrictly: {
    type: Boolean,
    default: true // 不关联
  },
  // 父类id字段名 (如果父子联动则必传该字段,不联动则不用传)
  parentValue: {
    type: String,
    default: 'parentValue'
  },
  // 回显的值是否可被删除 true: 可以删除；false：不能删除
  isCanDelete: {
    type: Boolean,
    default: true
  },
  // placeholder: {
  //   type: String,
  //   default: '---'
  // },
  // 不可删除报错
  errMessage: {
    type: String,
    default: 'Can not delete'
  },
  isSingle: {
    type: Boolean,
    default: false // 默认多选
  }
})

const defaultValue = ref([])

const data = reactive({
  selectTree: [], // 绑定el-option的值
  selectValue: [], // 文本框中的标签
  VALUE_NAME: props.defaultProps.value, // value转换后的字段
  VALUE_TEXT: props.defaultProps.label, // label转换后的字段
  treeLoading: false, // 加载loading~
  options: props.options // 选项数组
})

// 单选 String类型  多选 Array类型
watch(
  () => props.modelValue,
  (val, oldVal) => {
    // 字符串类型处理
    if (typeof val === 'string') {
      if (props.isSingle) {
        // 单选模式
        data.selectValue = val
      } else if (!val) {
        // 多选模式且值为空字符串
        nextTick(() => {
          data.selectValue = []
          defaultValue.value = []
        })
      }
    } else if (Array.isArray(val)) {
      // 数组类型处理
      if (val.length > 0) {
        // 数组非空
        data.selectValue = val
        defaultValue.value = val
      } else {
        // 数组为空
        data.selectValue = []
        handleReset()
      }
    }

    // 如果需要，可以在这里添加对val和oldVal的日志记录
    // console.log('------', typeof val, val, oldVal);
  },
  {
    immediate: true
  }
)

const selectDefaultValue = (val) => {
  if (Array.isArray(val) && val.length && !props.isSingle) {
    nextTick(() => {
      let datalist = treeNode.value.getCheckedNodes()
      if (!props.checkStrictly) {
        const parentList = datalist
          .filter((v) => v[props.defaultProps.children])
          .map((v) => v[data.VALUE_NAME])
        datalist = datalist.filter(
          (v) => parentList.indexOf(v[props.parentValue]) === -1
        )
      }
      data.selectTree = datalist
      data.selectValue = datalist.map((v) => v[data.VALUE_TEXT])
    })
  }
}

watch(
  () => store.state.system.tenantList,
  (newVal, oldVal) => {
    if (Array.isArray(newVal) && newVal.length > 0) {
      data.options = newVal
    }
  }
)

watch(
  () => props.options,
  (val, oldVal) => {
    data.options = val
    selectDefaultValue(val)
  }
)

watch(
  () => defaultValue.value,
  (val) => {
    selectDefaultValue(val)
    nextTick(() => {
      if (treeNode.value) {
        treeNode.value.setCheckedKeys(val)
      }
    })
  },
  { immediate: true }
)
// 全选
// const handlecheckAll = () => {
//   data.treeLoading = true
//   setTimeout(() => {
//     treeNode.value.setCheckedNodes(data.options)
//     data.treeLoading = false
//   }, 200)
// }
// 清空
const handleReset = () => {
  if (props.isCanDelete) {
    console.log(treeNode.value)
    setTimeout(() => {
      if (treeNode.value) {
        treeNode.value.setCheckedNodes([])
      }
    }, 200)
  } else {
    ElMessage.error(props.errMessage)
  }
}
/**
 * @description: 反选处理方法
 * @param {*} nodes 整个tree的数据
 * @param {*} refs  this.$refs.treeNode
 * @param {*} flag  选中状态
 * @param {*} seleteds 当前选中的节点
 * @return {*}
 */
// const batchSelect = (nodes, refs, flag, seleteds) => {
//   if (Array.isArray(nodes)) {
//     nodes.forEach((element) => {
//       refs.setChecked(element, flag, true)
//     })
//   }
//   if (Array.isArray(seleteds)) {
//     seleteds.forEach((node) => {
//       refs.setChecked(node, !flag, true)
//     })
//   }
// }
// 反选
// const handleReverseCheck = () => {
//   if (props.isCanDelete) {
//     data.treeLoading = true
//     setTimeout(() => {
//       const res = treeNode.value
//       const nodes = res.getCheckedNodes(true, true)
//       batchSelect(data.options, res, true, nodes)
//       data.treeLoading = false
//     }, 200)
//   } else {
//     ElMessage.error(props.errMessage)
//   }
// }
// 输入框关键字
const dataFilter = (val) => {
  treeNode.value.filter(val)
}

const handleNodeClick = (data, self, child) => {
  // 仅单选的条件下才赋值
  if (props.isSingle) {
    nextTick(() => {
      data.selectValue = data.name
      emits('update:modelValue', data.selectValue)
      emits('changeTreeData', data)
    })
  }
}
/**
 * @description: tree搜索过滤
 * @param {*} value 搜索的关键字
 * @param {*} dataValue  筛选到的节点
 * @return {*}
 */
const filterNode = (value, dataValue) => {
  if (!value) return true
  return (
    dataValue[props.defaultProps.label]
      .toLowerCase()
      .indexOf(value.toLowerCase()) !== -1
  )
}
/**
 * @description: 勾选树形选项
 * @param {*} dataValue 该节点所对应的对象
 * @param {*} self 节点本身是否被选中
 * @param {*} child 节点的子树中是否有被选中的节点
 * @return {*}
 */
const handleNodeChange = (dataValue, self, child) => {
  if (!props.isSingle) {
    const flag = defaultValue.value.some(
      (v) => v === dataValue[data.VALUE_NAME]
    )
    let datalist = treeNode.value.getCheckedNodes()

    if (!self && !props.isCanDelete && flag) {
      ElMessage.error(props.errMessage)
      treeNode.value.setChecked(dataValue, true, true)
    }
    if (!props.checkStrictly) {
      // 如果联动则需处理父子值关系
      const parentList = datalist
        .filter((v) => v[props.defaultProps.children])
        .map((v) => v[data.VALUE_NAME])
      datalist = datalist.filter(
        (v) => parentList.indexOf(v[props.parentValue]) === -1
      )
    }

    data.selectTree = datalist
    data.selectValue = datalist.map((v) => v[data.VALUE_TEXT])
    emits('changeSelectDataList', data.selectTree)
    emits('update:modelValue', handleValue(data.selectTree))
    emits('changeTreeData', data)
  }
}
// 移除单个标签
const removeTag = (tagName) => {
  const flagName = data.selectTree
    .filter(
      (v) =>
        v[data.VALUE_NAME] ===
        defaultValue.value.find((item) => item === v[data.VALUE_NAME])
    )
    .map((v) => v[data.VALUE_TEXT])
  const flag = flagName.includes(tagName)
  if (props.isCanDelete) {
    // 判断回显的值是否可删除
    data.selectTree = data.selectTree.filter(
      (v) => v[data.VALUE_TEXT] !== tagName
    )
    const selectTreeValue = data.selectTree.map((v) => v[data.VALUE_NAME])
    let setList = treeNode.value.getCheckedNodes()
    setList = setList.filter(
      (v) =>
        v[data.VALUE_NAME] ===
        selectTreeValue.find((item) => item === v[data.VALUE_NAME])
    )
    nextTick(() => {
      treeNode.value.setCheckedNodes(setList)
    })
    emits('changeSelectDataList', data.selectTree)
    emits('update:modelValue', handleValue(data.selectTree))
  } else {
    if (!flag) {
      // 判断回显时新增的是否可删除
      data.selectTree = data.selectTree.filter(
        (v) => v[data.VALUE_TEXT] !== tagName
      )
      const selectTreeValue = data.selectTree.map((v) => v[data.VALUE_NAME])
      let setList = treeNode.value.getCheckedNodes()
      setList = setList.filter(
        (v) =>
          v[data.VALUE_NAME] ===
          selectTreeValue.find((item) => item === v[data.VALUE_NAME])
      )
      nextTick(() => {
        treeNode.value.setCheckedNodes(setList)
      })
      emits('changeSelectDataList', data.selectTree)
      emits('update:modelValue', handleValue(data.selectTree))
    } else {
      data.selectValue = data.selectTree.map((v) => v[data.VALUE_TEXT])
      ElMessage.error(data.errMessage)
    }
  }
}
// 文本框清空
const clearAll = () => {
  console.log('被选中的值已经清空')
  data.selectTree = []
  treeNode.value.setCheckedNodes([])
  emits('clear')
  emits('changeSelectDataList', data.selectTree)
  emits('update:modelValue', props.isSingle ? '' : handleValue(data.selectTree))
  emits('changeTreeData', props.isSingle ? '' : handleValue(data.selectTree))
}
// 返回值处理
const handleValue = (val) => {
  return val.map((v) => v.id)
}
</script>

<style lang="scss" scoped>
.check-box {
  padding: 0 16px;
}

:deep(.el-scrollbar) {
  height: 300px;

  .el-select-dropdown__wrap {
    max-height: 300px;
    overflow: hidden;

    .el-select-dropdown__list {
      padding: 0;
    }
  }
}

.option-style {
  height: 260px !important;
  padding: 0 0 10px 0 !important;
  margin: 0;
}
</style>
