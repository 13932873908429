<template>
  <div class="upload-box">
    <el-upload
      :id="uuid"
      action="#"
      :class="[
        'upload',
        disabledAttribute ? 'disabled' : '',
        drag ? 'no-border' : ''
      ]"
      :multiple="false"
      :disabled="disabledAttribute"
      :show-file-list="false"
      :http-request="handleHttpUpload"
      :before-upload="beforeUpload"
      :drag="drag"
      :accept="fileType.join(',')"
    >
      <template v-if="modelValue">
        <img :src="modelValue" class="upload-image" alt="" />
        <div class="upload-handle" @click.stop>
          <div class="handle-icon" @click="editImg" v-if="!disabledAttribute">
            <el-icon><Edit /></el-icon>
            <span>{{ $t('general.edit') }}</span>
          </div>
          <div class="handle-icon" @click="imgViewVisible = true">
            <el-icon><ZoomIn /></el-icon>
            <span>{{ $t('general.view') }}</span>
          </div>
          <div class="handle-icon" @click="deleteImg" v-if="!disabledAttribute">
            <el-icon><Delete /></el-icon>
            <span>{{ $t('general.delete') }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="upload-empty">
          <slot name="empty">
            <el-icon><Plus /></el-icon>
          </slot>
        </div>
      </template>
    </el-upload>
    <div class="el-upload__tip">
      <slot name="tip"></slot>
    </div>
    <el-image-viewer
      v-if="imgViewVisible"
      @close="imgViewVisible = false"
      :url-list="[modelValue]"
    />
  </div>
</template>

<script setup>
import {
  defineProps,
  ref,
  computed,
  defineEmits,
  getCurrentInstance
} from 'vue'
import { generateUUID, getBase64 } from '@/utils/util'
import { useStore } from 'vuex'
import { ElMessage, ElNotification } from 'element-plus'
import { ZoomIn, Edit, Delete, Plus } from '@element-plus/icons'
import i18n from '@/i18n'
import { useRoute } from 'vue-router'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  drag: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fileSize: {
    type: Number,
    default: 2
  },
  fileType: {
    type: Array,
    default: () => ['.jpeg', '.png', '.jpg']
  },
  height: {
    type: String,
    default: '120px'
  },
  width: {
    type: String,
    default: '120px'
  },
  borderRadius: {
    type: String,
    default: '8px'
  },
  uploadApiAction: {
    type: String,
    default: ''
  },
  field: {
    type: String,
    required: true
  }
})

const route = useRoute()
const currentRouteId = route?.path
// 生成组件唯一id
const uuid = ref('id-' + generateUUID())

const imgViewVisible = ref(false)

const disabledAttribute = computed(() => props.disabled)

const emit = defineEmits(['update:modelValue'])
const { appContext } = getCurrentInstance()
const bus = appContext.config.globalProperties.$bus

const store = useStore()
const httpResult = ref(null)
// 图片上传
const handleHttpUpload = async (options) => {
  const formData = new FormData()
  formData.append('file', options.file)
  let result = ''
  if (props.uploadApiAction) {
    // 调用上传文件的接口 有接口的情况下
    result = await store.dispatch(props.uploadApiAction, formData)
    if (result?.code === 200) {
      ElNotification({
        title: t('general.warm-prompt-tip'),
        message: t('general.upload-success-tip'),
        type: 'success'
      })
    } else {
      ElNotification({
        title: t('general.warm-prompt-tip'),
        message: result?.msg,
        type: 'error'
      })
    }
  } else {
    // 没接口转成base64
    httpResult.value = await getBase64(options.file)
  }

  emit('update:modelValue', result?.data?.url)
  store.commit('system/setImgPathList', {
    key: currentRouteId,
    value: result?.path
  })
  bus.emit('imageFile', {
    field: props.field,
    file: options.file
  })
}

// 删除图片
const deleteImg = () => {
  emit('update:modelValue', '')
}

// 编辑图片
const editImg = () => {
  const dom = document.querySelector(`#${uuid.value} .el-upload__input`)
  dom && dom.dispatchEvent(new MouseEvent('click'))
}
const { t } = i18n.global
const imgTypeMap = {
  'image/jpeg': '.jpeg',
  'image/png': '.png',
  'image/gif': '.gif',
  'image/jpg': '.jpg'
}
// 文件上传前的hooks校验
const beforeUpload = (rawFile) => {
  const imgSize = rawFile.size / 1024 / 1024 < props.fileSize

  const imgType = props.fileType.includes(imgTypeMap[rawFile.type])
  if (!imgType)
    ElNotification({
      title: t('general.warm-prompt-tip'),
      message: t('general.uploaded-pics-not-match-format'),
      type: 'warning'
    })
  if (!imgSize)
    setTimeout(() => {
      // ElNotification({
      //   title: t('general.warm-prompt-tip'),
      //   message: t('general.uploaded-pics-not-match-size-tip', {
      //     size: props.fileSize
      //   }),
      //   type: 'warning'
      // })
      ElMessage.error('The image size max limit is ' + props.fileSize + 'M')
    }, 0)
  return imgType && imgSize
}

// // 图片上传成功
// const uploadSuccess = (response) => {
//   console.log(httpResult.value)
//   const { code, msg } = httpResult.value

// }

// // 图片上传失败
// const uploadError = () => {
//   ElNotification({
//     title: t('general.warm-prompt-tip'),
//     message: t('general.upload-failed-tip'),
//     type: 'error'
//   })
// }
</script>

<style lang="scss" scoped>
.is-error {
  .upload {
    :deep(.el-upload),
    :deep(.el-upload-dragger) {
      border: 1px dashed var(--el-color-danger) !important;
      &:hover {
        border-color: var(--el-color-primary) !important;
      }
    }
  }
}
:deep(.disabled) {
  .el-upload,
  .el-upload-dragger {
    cursor: not-allowed !important;
    background: #f5f7fa !important;
    border: 1px dashed #cdd0d6 !important;
    &:hover {
      border: 1px dashed #cdd0d6 !important;
    }
  }
}
.upload-box {
  .no-border {
    :deep(.el-upload) {
      border: none !important;
    }
  }
  :deep(.upload) {
    .el-upload {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: v-bind(width);
      height: v-bind(height);
      overflow: hidden;
      border: 1px dashed #cdd0d6;
      border-radius: v-bind(borderRadius);
      transition: var(--el-transition-duration-fast);
      &:hover {
        border-color: var(--el-color-primary);
        .upload-handle {
          opacity: 1;
        }
      }
      .el-upload-dragger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0;
        overflow: hidden;
        background-color: transparent;
        border: 1px dashed #cdd0d6;
        border-radius: v-bind(borderRadius);
        &:hover {
          border: 1px dashed var(--el-color-primary);
        }
      }
      .el-upload-dragger.is-dragover {
        background-color: var(--el-color-primary-light-9);
        border: 2px dashed var(--el-color-primary) !important;
      }
      .upload-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .upload-empty {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 30px;
        color: var(--el-color-info);
        .el-icon {
          font-size: 28px;
          color: var(--el-text-color-secondary);
        }
      }
      .upload-handle {
        position: absolute;
        top: 0;
        right: 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: rgb(0 0 0 / 60%);
        opacity: 0;
        transition: var(--el-transition-duration-fast);
        .handle-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 6%;
          color: aliceblue;
          .el-icon {
            margin-bottom: 40%;
            font-size: 130%;
            line-height: 130%;
          }
          span {
            font-size: 85%;
            line-height: 85%;
          }
        }
      }
    }
  }
  .el-upload__tip {
    line-height: 18px;
    text-align: left;
  }
}
</style>
