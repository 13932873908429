<template>
  <div class="upload-picture-list">
    <el-scrollbar>
      <el-upload
        v-model:fileList="fileList"
        class="upload-picture-list-element"
        action="#"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :http-request="handleHttpUpload"
        :on-remove="handleRemove"
        :on-exceed="handleExceed"
        :disabled="disabledAttribute"
        :before-upload="beforeImageUpload"
        :accept="fileType.join(',')"
        :limit="maxUploadTimes"
      >
        <el-icon><Plus /></el-icon>
      </el-upload>
    </el-scrollbar>
    <el-dialog v-model="dialogVisible">
      <img
        :src="dialogImageUrl"
        alt="Preview Image"
        style="max-width: 100%; height: auto; display: block; margin: 0 auto"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, defineEmits, watch, defineProps, computed } from 'vue'
import { Plus } from '@element-plus/icons'
import { useStore } from 'vuex'
import { getBase64 } from '@/utils/util'
import { ElMessage, ElNotification } from 'element-plus'

import i18n from '@/i18n'

const fileList = ref([])
const emit = defineEmits(['update:modelValue'])
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const store = useStore()

const props = defineProps({
  limitImgSize: {
    type: Number,
    default: 2
  },
  uploadApiAction: {
    type: String,
    default: ''
  },
  fileType: {
    type: Array,
    default: () => ['.jpeg', '.png', '.jpg']
  },
  modelValue: {
    type: String,
    default: ''
  },
  maxUploadTimes: {
    type: Number,
    default: 5
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

watch(
  () => fileList.value,
  (newVal, oldVal) => {
    console.log(newVal)
    if (newVal) {
      console.log(fileList.value)
      emit('update:modelValue', fileList.value)
    }
  },
  {
    deep: true
  }
)
// 照片墙初始化的时候回显
watch(
  () => props.modelValue,
  (newVal, oldVal) => {
    if (newVal) {
      console.log(newVal)
      // 判断当回显的值为字符串的时候才进行切割
      if (typeof newVal === 'string') {
        console.log(newVal)
        fileList.value = newVal?.split(',')?.map((item) => {
          return {
            url: item
          }
        })
      }
    } else {
      fileList.value = []
    }
  },
  {
    deep: true
  }
)

const disabledAttribute = computed(() => props.disabled)

const handleRemove = (uploadFile, uploadFiles) => {
  console.log(fileList.value)
  // 遍历fileList 删除对应的文件
  fileList.value = fileList.value.filter((item) => {
    return item.url !== uploadFile.url
  })
}

const handleExceed = (uploadFile, uploadFiles) => {
  ElMessage.error('The number of images max limit is ' + props.maxUploadTimes)
}

const imgTypeMap = {
  'image/jpeg': '.jpeg',
  'image/png': '.png',
  'image/gif': '.gif',
  'image/jpg': '.jpg'
}
const { t } = i18n.global
const beforeImageUpload = (file) => {
  const isLimit = file.size / 1024 / 1024 < props.limitImgSize
  const imgType = props.fileType.includes(imgTypeMap[file.type])
  if (!imgType)
    ElNotification({
      title: t('general.warm-prompt-tip'),
      message: t('general.uploaded-pics-not-match-format'),
      type: 'warning'
    })
  // const isExceedLimitTimes = fileList.value?.length >= 5
  // console.log(isExceedLimitTimes, fileList.value?.length)
  if (!isLimit) {
    ElMessage.error('The image size max limit is ' + props.limitImgSize + 'M')
  }
  // if (isExceedLimitTimes) {
  //   ElMessage.error('The number of images max limit is' + props.maxUploadTimes)
  // }

  return isLimit && imgType
}

const handlePictureCardPreview = (uploadFile) => {
  console.log(uploadFile.url)
  dialogImageUrl.value = uploadFile.url
  dialogVisible.value = true
}

const handleHttpUpload = async (options) => {
  const formData = new FormData()
  formData.append('file', options.file)
  let result = ''
  if (props.uploadApiAction) {
    // 调用上传文件的接口 有接口的情况下
    result = await store.dispatch(props.uploadApiAction, formData)
    // 判断当前返回结果中的MD5是否已经在文件列表中存在
    console.log(fileList.value)
    const isExist = fileList.value?.find((item) => item.md5 === result.md5)
    if (!isExist) {
      console.log(result)
      fileList.value.push(result?.data)
    } else {
      console.log(fileList.value)
      ElMessage.info('The same image file is not allowed to be uploaded')
    }
    if (result?.code === 200) {
      ElNotification({
        title: t('general.warm-prompt-tip'),
        message: t('general.upload-success-tip'),
        type: 'success'
      })
    } else {
      ElNotification({
        title: t('general.warm-prompt-tip'),
        message: result?.msg,
        type: 'error'
      })
    }
  } else {
    // 没接口转成base64
    result = await getBase64(options.file)
  }

  // store.commit('system/setImgPathList', {
  //   key: currentRouteId,
  //   value: result?.path
  // })
}
</script>

<style lang="scss" scoped>
.upload-picture-list {
  width: 100%; /* 设置容器宽度 */
  overflow-x: auto; /* 横向滚动条 */
  white-space: nowrap; /* 元素不换行 */
}

:deep(.upload-picture-list-element) {
  .el-upload--picture-card {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 120px;
    height: 120px;
    cursor: pointer;
    line-height: 146px;
    vertical-align: top;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 120px;
    height: 120px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
</style>
