<template>
  <el-upload
    class="upload-demo"
    ref="upload"
    :accept="acceptType"
    :file-list="fileList"
    :on-change="handleChange"
    action="#"
    :show-file-list="true"
    :on-success="onSuccess"
    :on-error="onError"
    :on-remove="onRemove"
    :before-upload="handleBeforeUpload"
    :http-request="handleHttpRequest"
  >
    <el-button style="margin-left: 8px" type="primary" size="mini">{{
      $t('general.select-file')
    }}</el-button>
  </el-upload>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  toRefs,
  watch,
  getCurrentInstance,
  onMounted,
  onBeforeUnmount
} from 'vue'
import { ElMessage, ElNotification } from 'element-plus'
import i18n from '@/i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const props = defineProps({
  limitSize: {
    type: Number,
    default: 2
  },
  acceptType: {
    type: String,
    default: '.gif, .jpeg, .png, .jpg, .exe',
    required: true
  },
  field: {
    type: String,
    required: true
  },
  bindValue: {
    required: true
  }
})
const route = useRoute()
const path = route.path
const cxt = getCurrentInstance()
const bus = cxt.appContext.config.globalProperties.$bus
const { t } = i18n.global
const store = useStore()
const emit = defineEmits(['uploadFileType', 'handleItemValidate'])
const fileList = ref([])
const upload = ref(null)
const handleChange = (file, files) => {
  if (fileList.value.length > 0) upload.value.clearFiles()
  // 如果resultMessage存在 就清空

  if (files.length > 0) {
    fileList.value = [files[files.length - 1]]
    // 这一步，是 展示最后一次选择的txt文件
  }

  const extension = '.' + file.name.substring(file.name.lastIndexOf('.') + 1)
  const acceptTypeList = props.acceptType.split(',')
  console.log(extension, acceptTypeList)
  const isTxt = acceptTypeList.includes(extension)
  const isLimit = file.size / 1024 / 1024 < props.limitSize
  if (!isTxt) {
    console.log(props.acceptType, 'props.acceptType')
    const extensionName = props.acceptType
    ElMessage.error(
      t('general.upload-file-format-type', { fileType: extensionName })
    )
    fileList.value = []
  } else {
    if (!isLimit) {
      ElMessage.error('The size has exceed the' + props.limitSize + 'M')
      fileList.value = []
    }
  }
  emit('uploadFileType', { file: file.raw, field: props.field })
}

onMounted(() => {
  bus.on('showFileName', (fileName) => {
    fileList.value.push({
      name: fileName
    })
  })
})

onBeforeUnmount(() => {
  bus.off('showFileName')
})

const { bindValue } = toRefs(props)
watch(bindValue, (newVal, oldVal) => {
  if (newVal === '') {
    fileList.value = []
  }
  if (newVal !== '' && oldVal === '') {
    // 做清除校验
    emit('handleItemValidate', props.field)
  }
})

const onSuccess = (res) => {
  if (res) {
    console.info('🚀 ~ Upload successfully')
  }
}
const onError = () => {
  console.info('Upload file failed')
}
const onRemove = () => {
  fileList.value = []
  emit('uploadFileType', { file: '', field: props.field })
  bus.emit('removeFileClick', { file: '', field: props.field })
  console.log('Remove the file or fail')
}

const currentExtension = ref('')
const handleBeforeUpload = (file) => {
  console.log('🚀 ~ handleBeforeUpload ~ file:', file)
  currentExtension.value = file.name.substring(file.name.lastIndexOf('.') + 1)
  const extension = '.' + file.name.substring(file.name.lastIndexOf('.') + 1)
  const isExtension = props.acceptType.includes(extension)
  const isLimit = file.size / 1024 / 1024 < props.limitSize
  // if (!isExtension) {
  //   // ElMessage.error('Incorrect file format')
  // } else {
  //   if (!isLimit) {
  //     ElMessage.error('The File size max limit is' + props.limitSize + 'M')
  //   }
  // }
  return isExtension && isLimit
}

const handleHttpRequest = async ({ file, onProgress, onSuccess, onError }) => {
  // 2. 创建 FormData 对象并添加文件
  const formData = new FormData()
  formData.append('file', file)
  const res = await store.dispatch('system/uploadFileType', {
    data: formData,
    type: currentExtension.value
  })
  if (!res) {
    ElNotification({
      title: t('general.warm-prompt-tip'),
      message: t('general.upload-success-tip'),
      type: 'success'
    })
  }
  console.log('🚀 ~ handleUpload ~ response:', res)
  if (Number(res?.code) === 200) {
    // 将文件路径进行存储
    store.commit('system/setFilePathList', {
      key: path,
      value: res?.data?.path
    })
    bus.emit('getFileHttpResponse', res?.data || {})
    ElMessage.success(res?.msg || 'Upload successfully')
  } else {
    ElMessage.error(res?.msg || 'Fail to upload')
  }
}

defineExpose({
  onRemove
})
</script>

<style>
input[type='file'] {
  display: none;
}
.upload-demo {
  display: flex;
  border: 1px solid #ccc;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.input-group .el-list-enter-active,
.el-list-leave-active {
  transition: none;
}

.input-group .el-list-enter,
.input-group .el-list-leave-active {
  opacity: 0;
}
.input-group .el-upload-list {
  height: 40px;
}

.input-group .el-upload-list__item-name {
  color: #606266;
  display: block;
  margin-right: 40px;
  overflow: hidden;
  padding-left: 4px;
  text-overflow: ellipsis;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  white-space: nowrap;
  flex: 1;
  width: 560px;
}

.input-group .el-upload {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: 0;
  line-height: 40px;
  margin-left: 10px;
}
</style>
