<template>
  <div class="ws-form">
    <div class="header">
      <slot name="header"></slot>
    </div>
    <el-form
      :label-position="labelPosition"
      ref="formRef"
      :rules="rulesRef"
      :model="modelValue"
    >
      <el-row>
        <template v-for="item in formItemsArr" :key="item.label">
          <el-col
            v-if="item.isShow === false ? false : true"
            v-bind="item.colLayout ? item.colLayout : colLayout"
          >
            <el-form-item
              :label="item.label ? $t(item.label) : ''"
              :style="itemStyle"
              :prop="item.field"
            >
              <template v-if="item.slotName" #label>
                <slot :name="item.slotName" :form="item" :field="item.field">
                </slot
              ></template>
              <template
                v-if="
                  item.type === 'input' ||
                  item.type === 'password' ||
                  item.type === 'textarea'
                "
              >
                <el-input
                  :placeholder="
                    item.placeholder
                      ? $t(item.placeholder, item.placeholderParams)
                      : defaultPlaceholder(
                          item.label,
                          item.labelParams,
                          item.otherOptions?.disabled
                        )
                  "
                  v-bind="item.otherOptions"
                  :show-password="item.type === 'password'"
                  :type="item.type === 'textarea' ? 'textarea' : ''"
                  :model-value="modelValue[`${item.field}`]"
                  @[item.event]="item.eventName"
                  clearable
                  @update:modelValue="handleValueChange($event, item.field)"
                  @blur="
                    handleInputBlurValueChange(
                      $event,
                      item.field,
                      modelValue[`${item.field}`]
                    )
                  "
                  @change="
                    handleWatchChange(
                      $event,
                      item.field,
                      item.isWatchCurrentItemValue
                    )
                  "
                />
              </template>
              <template v-if="item.type === 'autocomplete'">
                <el-autocomplete
                  :model-value="modelValue[`${item.field}`]"
                  v-bind="item.otherOptions"
                  clearable
                  @clear="clearSelect"
                  style="width: 100%"
                  :fetch-suggestions="
                    (queryString, cb) => {
                      querySearchAsync(queryString, cb, item.tableList)
                    }
                  "
                  :placeholder="
                    item.placeholder
                      ? $t(item.placeholder, item.placeholderParams)
                      : defaultPlaceholder(
                          item.label,
                          item.labelParams,
                          item.otherOptions?.disabled
                        )
                  "
                  @update:modelValue="handleValueChange($event, item.field)"
                ></el-autocomplete>
              </template>
              <template v-else-if="item.type === 'cascader'">
                <el-cascader
                  style="width: 100%"
                  v-bind="item.otherOptions"
                  :options="item.options"
                  clearable
                  filterable
                  @clear="clearSelect"
                  :model-value="modelValue[`${item.field}`]"
                  :placeholder="item.placeholder ? $t(item.placeholder) : ''"
                  @update:modelValue="handleValueChange($event, item.field)"
                />
              </template>
              <template v-else-if="item.type === 'treeSelect'">
                <tree-select
                  v-bind="item.otherOptions"
                  :options="item.options"
                  filterable
                  @clear="clearSelect"
                  :model-value="modelValue[`${item.field}`]"
                  @update:modelValue="handleValueChange($event, item.field)"
                  @change-tree-data="
                    handleSelectTreeWatchChange(
                      $event,
                      item.field,
                      item.isLinkageOptions
                    )
                  "
                />
              </template>
              <template v-else-if="item.type === 'select'">
                <el-select
                  :placeholder="
                    item.placeholder
                      ? $t(item.placeholder)
                      : defaultSelectPlaceholder(
                          item?.otherOptions?.disabled,
                          item?.otherOptions?.multiple
                        )
                  "
                  v-bind="item.otherOptions"
                  style="width: 100%"
                  :model-value="modelValue[`${item.field}`]"
                  @clear="clearSelect"
                  clearable
                  filterable
                  @change="
                    handleWatchChange($event, item.field, item.isLinkageOptions)
                  "
                  @update:modelValue="handleValueChange($event, item.field)"
                >
                  <el-option
                    v-if="
                      item.isShowAllOption === false ||
                      item?.otherOptions?.multiple ||
                      item?.otherOptions?.disabled
                        ? false
                        : true
                    "
                    label="---"
                    :value="item?.otherOptions?.defaultSelectValue || ''"
                  />
                  <el-option
                    v-for="option in item.options"
                    :key="option.value"
                    :value="option.value ? option.value : ''"
                    :label="option.label"
                  />
                </el-select>
              </template>
              <template v-else-if="item.type === 'datepicker'">
                <el-date-picker
                  :start-placeholder="
                    item.startPlaceholder
                      ? $t(item.startPlaceholder)
                      : $t('general.creation-start-date')
                  "
                  :end-placeholder="
                    item.endPlaceholder
                      ? $t(item.endPlaceholder)
                      : $t('general.creation-end-date')
                  "
                  :placeholder="
                    item.placeholder
                      ? $t(item.placeholder)
                      : defaultDatePlaceholder(item.otherOptions?.disabled)
                  "
                  style="width: 100%"
                  v-bind="item.otherOptions"
                  :model-value="modelValue[`${item.field}`]"
                  @update:modelValue="handleValueChange($event, item.field)"
                  :format="
                    item?.otherOptions?.format
                      ? item.otherOptions.format
                      : 'YYYY-MM-DD'
                  "
                  :valueFormat="
                    item?.otherOptions?.valueFormat
                      ? item.otherOptions.valueFormat
                      : 'YYYY-MM-DD'
                  "
                >
                </el-date-picker>
              </template>
              <template v-else-if="item.type === 'file'">
                <upload-file
                  ref="uploadComp"
                  :limitSize="item.otherOptions?.limitSize"
                  :acceptType="item.otherOptions?.acceptType"
                  style="width: 100%"
                  v-bind="item.otherOptions"
                  @uploadFileType="handleFileType"
                  :modelValue="modelValue[`${item.field}`]"
                  :field="item.field"
                  :bindValue="modelValue[`${item.field}`]"
                  @handleItemValidate="handleItemValidate"
                />
              </template>
              <template v-else-if="item.type === 'imgUpload'">
                <upload-img
                  :file-size="2"
                  :field="item.field"
                  v-bind="item.otherOptions"
                  :model-value="modelValue[`${item.field}`]"
                  @update:modelValue="
                    handleValueChange($event, item.field, item.type)
                  "
                >
                  <template #empty>
                    <el-icon class="el-icon-avatar">
                      <Avatar
                        v-if="
                          item?.uploadType === 'avatar' &&
                          !item?.otherOptions?.disabled
                        "
                      />
                      <Plus
                        v-if="
                          !item?.uploadType && !item?.otherOptions?.disabled
                        "
                      />
                    </el-icon>
                    <span
                      class="upload-tips"
                      v-if="item?.uploadTip && !item?.otherOptions?.disabled"
                      >{{ $t(item.uploadTip) }}</span
                    >
                    <span
                      class="upload-tips"
                      v-if="!item?.uploadTip && !item?.otherOptions?.disabled"
                      >{{ $t('profile.upload-profile-picture') }}</span
                    >
                  </template>
                  <template #tip>
                    <span v-if="!item?.otherOptions?.disabled">
                      {{ $t('profile.profile-picture-max-size') }}
                    </span>
                  </template>
                </upload-img>
              </template>
              <template v-else-if="item.type === 'pictureList'">
                <upload-picture-list
                  :field="item.field"
                  v-bind="item.otherOptions"
                  :model-value="modelValue[`${item.field}`]"
                  @update:modelValue="handleValueChange($event, item.field)"
                >
                </upload-picture-list>
              </template>
              <template v-else-if="item.type === 'mulImgUpload'">
                <!-- 待后端联调完善 没完全做好 -->
                <mul-upload-img
                  v-bind="item.otherOptions"
                  :modelValue="modelValue[`${item.field}`]"
                  @update:modelValue="handleValueChange($event, item.field)"
                ></mul-upload-img>
              </template>
              <template v-else-if="item.type === 'editor'">
                <custom-editor
                  ref="editor"
                  v-bind="item.otherOptions"
                  :modelValue="modelValue[`${item.field}`]"
                  @update:modelValue="handleValueChange($event, item.field)"
                ></custom-editor>
              </template>
            </el-form-item>
          </el-col>
        </template>
        <slot name="form"></slot>
      </el-row>
    </el-form>
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  defineExpose,
  ref,
  watch,
  nextTick,
  computed,
  getCurrentInstance
} from 'vue'
import uploadFile from '@/components/UpLoad/upload-file.vue'
import uploadImg from '@/components/UpLoad/upload-Img'
import uploadPictureList from '@/components/UpLoad/upload-picture-list.vue'
import mulUploadImg from '@/components/UpLoad/mul-upload-img.vue'
import customEditor from '@/components/editor/index.vue'
import { Avatar, Plus } from '@element-plus/icons'
import i18n from '@/i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import _ from 'lodash'
import TreeSelect from '@/components/TreeSelect/tree-select.vue'

const props = defineProps({
  // 表单元素
  formItems: {
    type: Array,
    default: () => []
  },

  isShowDefaultPlaceholder: {
    type: Boolean,
    default: true
  },
  labelPosition: {
    type: String,
    default: 'top'
  },
  itemStyle: {
    type: Object,
    default: () => ({
      padding: '5px 20px'
    })
  },
  colLayout: {
    type: Object,
    default: () => ({
      xl: 8, // >1920px 3个
      lg: 8,
      md: 12,
      sm: 24,
      xs: 24
    })
  },
  modelValue: {
    type: Object,
    required: true
  },
  validateRules: {
    type: Object,
    default: () => {}
  }
})

const { t } = i18n.global
// 1、判断validateRules不为空
const initialRules = props.validateRules ? _.cloneDeep(props.validateRules) : {}

// 2、定义格式化校验对象的messages
const handleFormatI18nMessages = (rules) => {
  // 判断rules是否为空对象
  const formatRule = {}
  if (JSON.stringify(rules) !== '{}') {
    Object.keys(rules).forEach((item) => {
      rules[item].forEach((subItem) => {
        if (subItem.message) {
          if (subItem.message.locale && subItem.message.range) {
            subItem.message = t(subItem.message.locale, subItem.message.range)
          } else {
            subItem.message = t(subItem.message)
          }
        }
      })
      formatRule[item] = rules[item]
    })
  }
  return formatRule
}

const store = useStore()

const rulesRef = ref({})
// 判断当前页是否是刚初始化的状态
let isInitialPage = true
// 判断当前是否处于正在校验的状态
let isCurrentValidate = false

// 获取当前的routes
const routes = useRoute()

// 根据路由判断当前页是否为edit
const isEditPage = routes.fullPath
  ? routes.fullPath.split('/').join('').includes('edit')
  : ''

const defaultPlaceholder = computed(() => {
  return (label, labelParams, isDisable) => {
    return props.isShowDefaultPlaceholder && !isDisable
      ? t('general.enter') + t(label, labelParams)
      : ''
  }
})

const defaultSelectPlaceholder = computed(() => {
  // 让多选框选择placeHolder为select，单选框则显示为空
  return (disabled, multiple) => {
    if (disabled) {
      return ' '
    } else return multiple ? '---' : ' '
  }
})

const defaultDatePlaceholder = computed(() => {
  return (isDisable) => {
    return props.isShowDefaultPlaceholder && !isDisable
      ? t('general.select-date')
      : ''
  }
})

watch(
  () => store.getters.language,
  (newVal, oldVal) => {
    if (newVal) {
      const params = _.cloneDeep(initialRules)

      rulesRef.value = handleFormatI18nMessages(params)

      // 清除切换语言时的side effect
      // todo 编辑是默认移除校验也需要进行messages的格式化 而不是移除
      if (!isInitialPage && !isCurrentValidate && !isEditPage) {
        nextTick(() => {
          formRef.value.clearValidate()
        })
      }

      isInitialPage = false
    }
  },
  {
    immediate: true
  }
)

const formItemsArr = ref([])
watch(
  () => props.formItems,
  (newVal, oldVal) => {
    if (newVal) {
      formItemsArr.value = newVal
    }
  },
  {
    immediate: true
  }
)

watch(
  () => props.modelValue,
  (newVal) => {
    console.log(newVal)
  }
)

// 处理文件类型
const handleFileType = (value) => {
  const { field, file } = value
  emit('update:modelValue', { ...props.modelValue, [field]: file })
}

const uploadComp = ref()

const emit = defineEmits(['update:modelValue', 'clearSelect'])
const handleValueChange = (value, field, type) => {
  emit('update:modelValue', {
    ...props.modelValue,
    [field]: value
  })
  // 触发校验 判断imgUpload
  if (type === 'imgUpload') {
    nextTick(async () => {
      await formRef.value.validateField(field)
    })
  }
}

const handleInputBlurValueChange = (event, field, value) => {
  emit('update:modelValue', {
    ...props.modelValue,
    [field]: value?.trim() || value
  })
}

const cxt = getCurrentInstance()
const bus = cxt.appContext.config.globalProperties.$bus

const handleWatchChange = (value, field, isLinkageOptions) => {
  if (isLinkageOptions) {
    bus.emit('linkageOptionsChange', {
      ...props.modelValue,
      [field]: value,
      fieldName: field
    })
  }
}

const handleSelectTreeWatchChange = (data, field, isLinkageOptions) => {
  if (isLinkageOptions) {
    bus.emit('linkageOptionsChange', {
      ...props.modelValue,
      [field]: data?.id || '',
      fieldName: field
    })
  }
}
const formRef = ref()

const handleValidate = async () => {
  isCurrentValidate = true
  let result = false
  await new Promise((resolve) => {
    formRef.value.validate((valid) => {
      result = valid
      resolve()
    })
  })
  return result
}

const handleValidateField = (fieldName) => {
  if (
    fieldName &&
    (props.modelValue[fieldName] || props.modelValue[fieldName] === 0)
  ) {
    nextTick(async () => {
      await formRef.value.validateField(fieldName)
    })
  }
}

const handleResetValidate = (isNextTick = false) => {
  isCurrentValidate = false // 根据条件判断是否需要异步
  if (isNextTick) {
    nextTick(() => {
      formRef.value.clearValidate() // 清除校验状态
      formRef.value.resetFields() // 重置表单字段
    })
  } else {
    formRef.value.clearValidate() // 清除校验状态
    formRef.value.resetFields() // 重置表单字段
  }
}

const handleItemValidate = (item) => {
  formRef.value.clearValidate(item)
}

const querySearchAsync = (queryString, cb, tableList) => {
  var results = queryString
    ? tableList.filter(createStateFilter(queryString))
    : tableList
  cb(results)
}
// 模糊搜索匹配方法
const createStateFilter = (queryString) => {
  return (state) => {
    return !!state.value.toLowerCase().match(queryString.toLowerCase())
  }
}

const clearSelect = () => {
  emit('clearSelect')
}
defineExpose({
  handleValidate,
  handleResetValidate,
  handleValidateField,
  handleItemValidate
})
</script>

<style lang="scss" scoped>
.ws-form {
  padding-top: 22px;
  border: 1px solid #e9e9eb;
  border-radius: 5px;
  .upload-tips {
    line-height: 18px;
  }
}
</style>

<style>
.ws-form .el-form-item__label {
  flex: 0 0 auto;
  text-align: right;
  font-size: var(--el-form-label-font-size);
  color: var(--el-text-color-regular);
  line-height: 30px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  font-weight: bold;
}

.ws-form .el-form--label-top .el-form-item__label {
  display: block;
  text-align: left;
  /* padding: 0 0 10px 0; */
}

.ws-form .el-form-item__label {
  flex: 0 0 auto;
  /* text-align: right; */
  font-size: var(--el-form-label-font-size);
  color: var(--el-text-color-regular);
  line-height: 24px;
  /* padding: 0 12px 0 0; */
  box-sizing: border-box;
}

.ws-form .el-textarea.is-disabled .el-textarea__inner {
  font-size: var(--el-form-label-font-size);
  color: var(--el-text-color-regular);
  line-height: 24px;
}

.ws-form .el-form--label-top .el-form-item__label {
  display: block;
  text-align: left;
  padding: 0 0 2px 0;
}

.ws-form .el-form--label-top .el-form-item__label {
  display: block;
  text-align: left;
  padding: 0 0 2px 0;
}

.ws-form .el-form-item {
  margin-bottom: 13px;
}

.ws-form .el-input.is-disabled .el-input__inner {
  background-color: var(--el-disabled-fill-base);
  border-color: var(--el-disabled-border-base);
  color: #767778;
  cursor: not-allowed;
}

.ws-form .el-form-item__content {
  display: flex;
  align-items: center;
}

.ws-form .el-form-item__error {
  margin-left: 10px;
}
</style>
