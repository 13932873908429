<template>
  <div v-if="isShowEditor" class="editor-wrap">
    <Toolbar
      v-if="isShowToolBar"
      class="toolbar"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
    />
    <Editor
      ref="editor"
      id="editor"
      class="editor"
      v-model="editorValue"
      :defaultConfig="editorConfig"
      @onCreated="handleCreated"
      @onBlur="handleEditorBlur"
    />
  </div>
</template>
<script setup>
import {
  onBeforeUnmount,
  ref,
  watch,
  shallowRef,
  defineProps,
  defineEmits,
  nextTick,
  defineExpose,
  onMounted
} from 'vue'
import i18n from '@/i18n'
import store from '@/store'
import { ElMessage } from 'element-plus'

import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { i18nChangeLanguage } from '@wangeditor/editor'
import { handleIsRight } from '@/utils/util'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  maxLength: {
    type: Number,
    default: Infinity
  },
  isShowToolBar: {
    type: Boolean,
    default: true
  },
  placeholder: {
    type: String,
    default: 'profile.content-v'
  }
})

const emit = defineEmits(['update:modelValue'])
const editorValue = ref('')

const editor = ref(null)

const insertText = (text) => {
  const before = editorRef.value.getText()
  editorRef.value.insertText(text)
  const after = editorRef.value.getText()

  const result = handleIsRight(before, after, text)
  if (!result) {
    editorRef.value.undo()
    ElMessage.error(t('general.insert-content-warning-tip'))
  }
}

/**
 * 计算富文本输入框内输入字符的长度（不包含p、img、video等标签，仅计算输入文字的长度）
 */
const getTextLength = () => {
  const newHtml = editorRef.value.getText()
  return newHtml.replace(/<[^>]+>/g, '').length
}

const onFocus = () => {
  editorRef.value.focus()
}
// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()
const { t } = i18n.global

const toolbarConfig = {
  excludeKeys: [
    'group-video',
    'uploadVideo',
    'group-image',
    'uploadImage',
    'insertImage',
    'fullScreen'
  ]
}
const editorConfig = {
  placeholder: props.disabled ? '' : t(props.placeholder),
  uploadImgShowBase64: true, // 将图片以 Base64 的形式显示
  // uploadImgServer: '/fake/upload', // 配置一个假的上传地址，也可以是实际的上传图片服务地址
  MENU_CONF: {
    // 配置上传服务器地址
    uploadImage: {
      base64LimitSize: 1024 * 1024 * 10 // 10MB 小于该值就插入 base64 格式（而不    上传），默认为 0
      // server: '/api/uploadImage'
    }
  }
}

const isShowEditor = ref(true)

watch(
  () => props.modelValue,
  (value) => {
    editorValue.value = value
  }
)

watch(editorValue, (value) => {
  emit('update:modelValue', value)
})
watch(
  () => store.getters.language,
  () => {
    isShowEditor.value = false
    nextTick(() => {
      isShowEditor.value = true
      i18nChangeLanguage(store.getters.language === 'zh' ? 'zh-CN' : 'en')
    })
  },
  {
    immediate: true
  }
)

onMounted(() => {
  if (editorRef.value) {
    // 在初始化完成后再将 uploadImgServer 设置为空字符串，确保配置的时机正确

    editorRef.value.$editor.config.onchange = () => {
      editorRef.value.$emit('change', editorRef.value.$editor.getHtml())
      // editorRef.value.$editor.$txt.eventHooks.pasteEvents.push({
      //   selector: '.wangEditor-txt',
      //   fn: (e) => {
      //     const clipboardData = e.clipboardData || window.clipboardData
      //     if (clipboardData) {
      //       const items = clipboardData.items

      //       for (const item of items) {
      //         if (item.kind === 'file' && item.type.includes('image')) {
      //           const blob = item.getAsFile()

      //           const reader = new FileReader()
      //           reader.onload = (event) => {
      //             const base64 = event.target.result
      //             editorRef.value.$editor.command(
      //               null,
      //               'insertHTML',
      //               `<img src="${base64}"/>`
      //             )
      //           }

      //           reader.readAsDataURL(blob)
      //         }
      //       }
      //     }
      //   }
      // })
    }
  }
  nextTick(() => {
    editorConfig.uploadImgServer = ''
  })
})

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})
const handleCreated = (editor) => {
  editorRef.value = editor // 记录editor实例
  if (props.disabled) {
    editorRef.value.disable()
  }
}

const handleEditorBlur = () => {
  const totalLens = getTextLength()
  if (totalLens > props.maxLength) {
    ElMessage.warning(
      t('general.length-between-area', {
        minLength: 1,
        maxLength: props.maxLength
      })
    )
  }
}
defineExpose({ editorRef, insertText, onFocus, handleEditorBlur })
</script>
<style scoped lang="scss">
.editor-wrap {
  width: 100%;
  border: 1px solid #ccc;
  .toolbar {
    border-bottom: 1px solid #ccc;
  }
  .editor {
    height: 500px !important;
    overflow-y: hidden;
  }
}
</style>
